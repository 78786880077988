import {createRouter, createWebHistory} from "vue-router";
import TheMain from "../components/TheMain.vue";
import TheGen1 from "../components/Gen1.vue";


const routes = [
    {
        path: '/',
        name: 'Main',
        component: TheMain
    },
    {
        path: '/g1',
        name: 'Gen1',
        component: TheGen1
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router;