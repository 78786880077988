<template>
    <h1>The Generator 1</h1>
  <div class="main">
    <div class="left_pannel">
      <h2>Component</h2>
      <div class="fg">
        <div>Base</div>
        <input v-model="base" type="range" min="1" max="3"/>
      </div>
      <div class="fg">
        <div>Multiply</div>
        <input v-model="multiply" type="range" min="1" max="30"/>
      </div>
      <div class="fg">
        <div>Palettes</div>
        <input v-model="palettesNumber" type="range" min="0" :max="`${palettes.length-1}`" />
      </div>
      <div class="fg">
        <div>Palettes Alhoritm</div>
        <input v-model="paletesAlhoritm" type="range" min="0" :max="`${palettesAlh.length-1}`" />
      </div>
<div class="origin_container">
  <input id="origin_x" v-model="cropPosition[0]" type="range" min="0" max="1000" />
  <input id="origin_y"  v-model="cropPosition[1]" type="range" min="0" max="1000" />

  <svg
        xmlns="http://www.w3.org/2000/svg"
        v-html="`${origin} ${cropSquare}` "
        viewBox="0 0 1000 1000"
/>
</div>
    </div>
    <div class="center_pannel">
      <svg
          id="theCanvas"
          ref="svgCanvas"
          xmlns="http://www.w3.org/2000/svg"
          :viewBox="`0 0 ${canvaSize[0]} ${canvaSize[1]}`"
      >
        <defs>
          <g id="theorigin"
            v-html="`${origin}`"
          />
          <clipPath id="thecrop">
            <rect :x="`${cropPosition[0]}`"
                  :y="`${cropPosition[1]}`"
                  :width="`${itemSize[0]}`"
                  :height="`${itemSize[1]}`"
                  :transform="`rotate(${-rotation} ${Number(cropPosition[0])+Number(itemSize[0]/2)} ${Number(cropPosition[1])+Number(itemSize[1]/2)})`"
            />
          </clipPath>
        </defs>

        <use
            v-for="(it,index) in theItems"
            :key="`origin${index}`"
            :id="`origin_${index}`"
            :x="`${it.px - cropPosition[0]}`"
            :y="`${it.py - cropPosition[1]}`"
            :width = "`${itemSize[0]}`"
            :height = "`${itemSize[1]}`"
            :transform = "`${it.transform}`"
            v-html="`${origin}`"
            clip-path="url(#thecrop)"
            xlink:href="#theorigin"
        />
      </svg>


    </div>
    <div class="right_pannel">
      <h2>Instruments</h2>
        <div class="fg">
          <div>Canvas X</div>
          <input v-model="canvaSize[0]" type="range" min="100" max="3000"/>
        </div>
        <div class="fg">
          <div>Canvas Y</div>
          <input v-model="canvaSize[1]" type="range" min="100" max="3000"/>
        </div>
      <div class="fg">
      <div>Cols</div>
          <input v-model="grid[0]" type="range" min="1" max="30"/>
        </div>
        <div class="fg">
          <div>Rows</div>
          <input v-model="grid[1]" type="range" min="1" max="30"/>
        </div>
        <div class="fg">
          <div>Rotation</div>
          <input v-model="rotation" type="range" min="-360" max="360"/>
        </div>
      <div class="instrument_buttons">
        <button @click="useRandom">Random</button>
        <button @click="downloadImg">Download</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheGen1",
  data() {
    return {
      grid: [5, 5],
      multiply: 20,
      originSize: [500,500],
      canvaSize: [1000,700],
      palettesNumber: 0,
      rotation: 0,
      base:1,
      baseNumber: 3,
      scale:1,
      paletesAlhoritm: 0,
      cropPosition: [350,650],
      palettesAlh: [
          [0,1,2,3,4],
          [0,3,2,1,4],
          [3,2,4,0,1],
          [0,1,2,3,4],
          [2,4,0,1,3],
      ],
      palettes: [
        ['#8ecae6','#219ebc','#023047','#ffb703','#fb8500'],
        ['#264653','#2a9d8f','#e9c46a','#f4a261','#e76f51'],
        ['#ffbe0b','#2a9d8f','#fb5607','#8338ec','#3a86ff'],
        ['#ff9f1c','#ffbf69','#ffffff','#cbf3f0','#2ec4b6'],
      ]
    }
  },
  computed: {
    svgCanvas() {
      return this.$refs.svgCanvas
    },
    origin() {
      let obj = '';
        for (let i=0; i<this.multiply; i++){
          const fill = this.palettes[this.palettesNumber][this.palettesAlh[this.paletesAlhoritm][i%this.palettesAlh[this.paletesAlhoritm].length]]
          switch(Number(this.base)) {
            case 1: {
              const cx = this.originSize[1] - Number(this.multiply/2+i*Number(this.originSize[1]/this.multiply))
              obj += '<circle cx="'+this.originSize[1]+'" cy="'+this.originSize[1]+'" r="'+cx+'" fill="'+fill+'"/>'
            }
              break
            case 2: {
              const x = (this.originSize[1]/this.multiply)*i
              const w = (this.originSize[1] - x)*2
              obj += '<rect x="'+x+'" y="'+x+'" width="'+w+'" height="'+w+'" fill="'+fill+'"/>'
            }
              break
            case 3: {
              const x = (this.originSize[1]/this.multiply)*i*1.42
              const points = '500 '+x+', '+(x/1.42)+' '+(1000 -x/2.5)+', '+(1000 - x/1.42)+' '+(1000 - x/2.5)

              obj += '<polygon points="'+points+'"  fill="'+fill+'"/>'
            }
              break
          }
        }
      return obj
    },
    cropSquare() {
      return '<rect ' +
          'x="'+this.cropPosition[0]+'" ' +
          'y="'+this.cropPosition[1]+'" ' +
          'width="'+this.itemSize[0]+'"  ' +
          'height="'+this.itemSize[1]+'" ' +
          'fill="none" ' +
          'stroke-width="10" ' +
          'stroke="#000000" ' +
          'transform="rotate('+(-Number(this.rotation))+' '+(Number(this.cropPosition[0])+this.itemSize[0]/2)+' '+(Number(this.cropPosition[1])+this.itemSize[1]/2)+')" ' +
          '/>'
    },
    itemSize() {
      return [this.canvaSize[0]/this.grid[0],this.canvaSize[1]/this.grid[1]]
    },
    theItems() {
      let obj = []
      for (let x=0; x<this.grid[0]; x++){
        for (let y=0; y<this.grid[1]; y++){
          const px = this.itemSize[0]*x
          const py = this.itemSize[1]*y
          const center = [px+(this.itemSize[0]/2),py+(this.itemSize[1]/2)]
          const rot = this.rotation
          const scl = this.scale
          let rotate = ''
          if(rot != 0){
            rotate = 'rotate('+rot+' '+center[0]+' '+center[1]+') '
          }
          let scale = ''
          if (scl != 1){
            scale = 'scale('+scl+')'
          }

          obj.push({
            px:px,
            py:py,
            transform: rotate+' '+scale,
            compensationRotate: -rot,
            compensationScale: 1/scl

          })
        }
      }
      return obj
    }

  },
  methods: {
    useRandom() {
      this.grid =  [3+Math.floor(Math.random() * 7), 3+Math.floor(Math.random() * 7)]
      this.multiply =  3+Math.floor(Math.random() * 27)
      this.palettesNumber = Math.floor(Math.random() *  this.palettes.length)
      this.rotation = -360 +Math.floor(Math.random() * 360*2)
      this.base = 1+Math.floor(Math.random() *  (Number(this.baseNumber)))
      this.paletesAlhoritm =  Math.floor(Math.random() *  this.palettesAlh.length)
      this.cropPosition =  [500-Math.floor(Math.random() * 250),500-Math.floor(Math.random() * 250)]
    },
    getTheToday() {
      let today = new Date()
      const year = today.getFullYear()
      const month = String(today.getMonth() + 1).padStart(2, '0')
      const day = String(today.getDate()).padStart(2, '0')
      const hours = String(today.getHours()).padStart(2, '0')
      const minutes = String(today.getMinutes()).padStart(2, '0')
      const seconds = String(today.getSeconds()).padStart(2, '0')
      today = year + '' + month + '' + day + '_' + hours + '' + minutes + '' + seconds
      return today
    },
    downloadImg() {
      const today = this.getTheToday()
      if (typeof this.svgCanvas === 'object' && this.svgCanvas !== null
      ) {
        const stringSvg = new XMLSerializer().serializeToString(this.svgCanvas)
        const element = document.createElement('a')
        element.download = `${today}.svg`
        element.href = 'data:application/octet-stream;base64,' + window.btoa(stringSvg)
        element.click()
        element.remove()
      }
    },

  }

}
</script>

<style scoped>

body, p , h1, h2, h3{
  color: #fff;
}

h1 {
  height: 40px;
  background-color: #2a1515;
  padding: 0;
  margin: 0;
}
.main {
  display: grid;
  grid-template-columns: 300px 1fr 300px;
  width: 100vw;
  height: Calc(100vh - 40px);
}

.left_pannel, .right_pannel{
  background-color: #2a1515;
}

.origin_container{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.origin_container svg{
  width: 80%;
}

.origin_container input{
  width: 100%;
}

#origin_y{
  position: relative;
  top: 130px;
  left: -45%;
  transform: rotate(90deg);
}

input[type="range"] {
  -webkit-appearance: none;
  height: 4px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  width: 90%;
  margin: 0;
}

input[type="range"]:hover {
  opacity: 1;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 25px;
  background: #ffffff;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 10px;
  height: 25px;
  background: #ffffff;
  cursor: pointer;
}

.fg div{
  color: white;
  margin-bottom: 10px;
  margin-top: 10px;
}

.origin_container{
  margin-top: 20px;
}

.center_pannel svg{
  height: 100%;
  width: 100%;
  max-height: Calc(100vh - 40px) ;
}

.instrument_buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.instrument_buttons button{
  color:#fff;
}
.instrument_buttons button:hover{
  color: #000000;
}

#theCanvas {
  height: Calc(100vh - 40px);
  width: Calc(100vw - 600px);
  box-sizing: border-box;
}

</style>